import { Button } from '@lemonenergy/lemonpie-3'
import { BrandSun, Help, Forward } from '@lemonenergy/lemonpie-icons'
import type { MetaFunction, LinksFunction } from '@remix-run/node'
import { Link, json } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import { useState } from 'react'
import { useTransitionMap } from 'react-transition-state'

import TrustedByItem from './__components/TrustedByItem'
import FaqDialog from './cadastro/informacoes-pessoais/__components/FaqDialog'

import HomeBackground from 'public/images/bg-acquisition-flow-home.svg'
import BaseView from '~/components/v3/sharedViews/BaseView'
import constants from '~/constants/index.server'
import paths from '~/constants/paths'
import { trustedByItems, type TrustedByItemsKeys } from '~/constants/trustedBy'
import cacheUtils from '~/utils/cache.utils.server'
import { commonMetaTags } from '~/utils/meta-tags.utils'
import { captureClick } from '~/utils/tracking'

export const handle = {
  variant: 'dark',
  isHeaderHidden: true,
  lgpdOptOut: true,
  disableFreshChat: true,
}
export const loader = async () => {
  return json(
    {
      whatsappNumber: constants.whatsappNumber,
    },
    {
      headers: cacheUtils.createCacheHeaders({
        cdnStrategy: 'FOR_ONE_QUARTER',
        browserStrategy: 'FOR_ONE_HOUR',
      }),
    },
  )
}

export const meta: MetaFunction<typeof loader> = ({ location }) =>
  commonMetaTags({
    title: 'Quero Economizar com a Lemon Energia',
    description:
      'Inicie seu cadastro agora mesmo e garanta mais economia em sua conta de luz.',
    pathname: location.pathname,
  })

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preload',
      href: HomeBackground,
      as: 'image',
    },
    ...trustedByItems.map(item => ({
      rel: 'preload',
      href: `${item.src}?height=12`,
      imageSrcSet: `${item.src}?height=12 1x, ${item.src}?height=24 2x, ${item.src}?height=36 3x`,
      as: 'image',
    })),
  ]
}

const AcquisitionFlowHome = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const animationTimeout = 3000

  const transition = useTransitionMap<TrustedByItemsKeys>({
    timeout: animationTimeout,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
  })

  const onDialogClose = () => {
    captureClick('Fechar modal de FAQ')()
    setIsDialogOpen(false)
  }
  const openDialog = () => {
    captureClick('Ajuda')()
    setIsDialogOpen(true)
  }
  return (
    <>
      <BaseView.Root variant="expressive">
        <BaseView.Head className="flex justify-between gap-x-large py-large text-white-100">
          <BaseView.Logo variant="normal" />
          <button onClick={openDialog}>
            <Help size="large" aria-label="open-modal" />
          </button>
        </BaseView.Head>
        <BaseView.Body className="content-grid content-fullwidth">
          <BaseView.Content className="content-fullwidth">
            <div className="col-[fullwidth] overflow-x-hidden flex justify-center items-center">
              <img
                src={HomeBackground}
                className="w-full min-w-[400px] h-auto aspect-[629/406] max-h-[350px]"
                alt=""
              />
            </div>
            <article className="pt-large sm:pb-large flex flex-col gap-y-small">
              <h3 className="flex gap-x-smallest items-center min-h-large">
                <BrandSun className="text-[#8AFFB6]" size="small" />
                <span className="fg-inverted-main typography-section">
                  Quem confia na Lemon
                </span>
                <div
                  className="h-24 ml-4 overflow-hidden grid grid-cols-1 *:col-start-1 *:row-start-1"
                  data-testid="trusted-by-logos"
                >
                  {trustedByItems.map(({ id, ...item }, index) => (
                    <TrustedByItem
                      key={id}
                      itemKey={id}
                      nextItemKey={
                        trustedByItems[(index + 1) % trustedByItems.length].id
                      }
                      initialEntered={index === 0}
                      animationTimeout={animationTimeout}
                      {...item}
                      {...transition}
                    />
                  ))}
                </div>
              </h3>
              <h1 className="mt-12 text-24 leading-[1.75rem] -tracking-[0.02rem] font-medium fg-inverted-main">
                Energia limpa sem instalação, sem custo extra e com economia
                todo mês
              </h1>
              <h2 className="typography-section fg-expressive-inverted">
                Saiba em poucos minutos a economia disponível para a
                distribuidora de energia da sua região
              </h2>
            </article>
          </BaseView.Content>
          <BaseView.Footer>
            <Button
              onClick={(e: BaseSyntheticEvent) => captureClick()(e)}
              variant="primary"
              inverted
              asChild
              className="justify-between"
            >
              <Link to={paths.ACQUISITION_FLOW_REGISTER_PERSON_DATA}>
                <span>Iniciar cadastro digital</span>
                <Forward size="main" />
              </Link>
            </Button>
          </BaseView.Footer>
        </BaseView.Body>
      </BaseView.Root>
      <FaqDialog isOpen={isDialogOpen} onClose={onDialogClose} />
    </>
  )
}

export default AcquisitionFlowHome
